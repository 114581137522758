export const FETCH_STAKE = 'FETCH_STAKE';
export const STAKE_VALUE = 'STAKE_VALUE';
export const SUM_STAKE = 'SUM_STAKE';
export const TOTAL_STAKERS = 'TOTAL_STAKERS';
export const STAKED_SUPPLY = 'STAKED_SUPPLY';
export const LAST_UPDATED_DATE = 'LAST_UPDATED_DATE';
export const LEADERBOARD_LIST = 'LEADERBOARD_LIST';
export const DATA_SOURCE = 'DATA_SOURCE';
export const LEADERBOARD_COLUMNS = 'LEADERBOARD_COLUMNS';
export const STAKE_EARNINGS_COLUMN = 'STAKE_EARNINGS_COLUMN';
export const STAKE_EARNINGS_DATA = 'STAKE_EARNINGS_DATA';
export const IS_LOADING = 'IS_LOADING';
export const IS_ERROR = 'IS_ERROR';
