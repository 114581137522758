import React, { Component, /* useState, useEffect */ } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Table } from 'antd';

import Breakpoint from 'react-socks';
import Binance from "../../clients/binance"

import { Icon, H1, Button/*, Text */ } from '../Components'
import { connect } from 'react-redux';

const homeStyles = {
    marginLeft: 0,
    marginTop: 40,
    backgroundColor: "#101921"
}

const iconStyles = {
    marginTop: 140,
    backgroundColor: "#101921"
}

const SYMBOL = "RAVEN-F66"

class Home extends Component {

    /*    constructor(props) {
            super(props);
            // this.getPrice()
            // this.setState({"price" : 0})
        }*/

    getPrice() {
        Binance.price(SYMBOL)
            .then((response) => {
                this.setState({ "price": response })
            })
            .catch((error) => {
                console.error(error)
            })
    }


    render() {

        const { stake, leaderboard } = this.props;

        console.log('isError', stake.isError);

        if (!this.state || !this.state.price) {

            return (
                <div style={{ backgroundColor: "#101921" }}>
                    <Row style={{}}>
                        <Col xs={24} sm={1} md={2} lg={3}>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={9} style={homeStyles}>

                            <H1>PROOF OF STEAK</H1>
                            <br></br>
                            <h4 style={{ color: "#848E9C" }}><span>RAVEN STAKING PROGRAM</span>
                            </h4>
                            <br></br>
                            <p><span aria-labelledby="jsx-a11y/accessible-emoji" role="img">🥩</span> Show your support and commitment in securing a network of compute nodes that are utilized for the purposes of AI training where speed is the key. </p>
                            <p><span aria-labelledby="jsx-a11y/accessible-emoji" role="img">🥩</span> Stake RAVEN to help facilitate secure transactions that take place inside Raven Ecosystem.</p>
                            <p><span aria-labelledby="jsx-a11y/accessible-emoji" role="img">🥩</span> Receive a reward every month on staked RAVEN</p>
                            <br></br>
                            <Link to="/stake">
                                <Button style={{ height: 40, width: 250 }}>STAKE NOW</Button>
                            </Link>
                            <br></br>
                            <br></br>

                        </Col>

                        <Col xs={24} sm={2} md={2} lg={2}>
                        </Col>

                        <Breakpoint medium up>
                            <Col xs={24} sm={8} md={8} lg={9} style={iconStyles}>
                                <Icon icon="raven-pos-banner" style={{ width: 450 }} />
                            </Col>
                        </Breakpoint>

                        <Col xs={24} sm={1} md={2} lg={3}>
                        </Col>

                    </Row>

                    {!stake.isError &&
                        <div>
                            <Row style={{ marginTop: 100 }}>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                                <Col xs={24} sm={11} md={10} lg={9}>
                                    <h4 style={{ color: "#848E9C" }}>NUMBER OF STAKERS:</h4>
                                    <H1>{stake.totalStakers}</H1>
                                </Col>

                                <Col xs={24} sm={11} md={10} lg={9}>

                                </Col>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                            </Row>

                            <Row style={{ marginTop: 50 }}>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                                <Col xs={24} sm={13} md={12} lg={11}>
                                    <h4 style={{ color: "#848E9C" }}>TOTAL STAKED:</h4>
                                    <H1> {stake.sumStake} RAVEN</H1>
                                    {/* <H1> ${(stake.sumStake * this.state.price * 100)/100} USD</H1> */}
                                </Col>

                                <Col xs={24} sm={9} md={8} lg={7}>
                                    <h4 style={{ color: "#848E9C" }}>STAKED SUPPLY:</h4>
                                    <H1>{stake.stakedSupply}%</H1>
                                </Col>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                            </Row>

                            <Row style={{ marginTop: 50 }}>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                                <Col xs={12} sm={11} md={10} lg={9}>
                                    <h4 style={{ color: "#848E9C" }}>LAST UPDATED:</h4>
                                    <h4 style={{ color: "#FFF" }}>{stake.lastUpdatedDate}</h4>
                                </Col>

                                <Col xs={12} sm={11} md={10} lg={9}>
                                </Col>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                            </Row>

                            <Row style={{ marginTop: 100 }}>

                                <Col xs={24} sm={1} md={2} lg={3}>
                                </Col>

                                <Col xs={24} sm={22} md={20} lg={18}
                                    style={{ backgroundColor: '#D8D8D8', borderRadius: 5, paddingBottom: 5 }}>
                                    <Table dataSource={stake.dataSource} columns={leaderboard.leaderboardColumns}
                                        pagination={false} size={'middle'}
                                        title={() => 'LEADERBOARD'} />
                                </Col>

                            </Row>

                            <Row style={{ marginTop: 30 }}>
                                <Col xs={2} sm={4} md={6} lg={8} xl={8}>

                                </Col>
                                <Col xs={2} sm={4} md={6} lg={8} xl={4}>

                                </Col>
                                <Col xs={2} sm={4} md={6} lg={8} xl={5}>

                                </Col>
                                <Col xs={20} sm={16} md={12} lg={8} xl={3}>
                                    <Link to="/leaderboard">
                                        <Button style={{ height: 40, width: 237 }}>VIEW ALL</Button>
                                    </Link>
                                </Col>

                            </Row>
                        </div>
                    }

                    {stake.isError &&
                        <Row style={{ marginTop: 100 }}>

                            <Col xs={24} sm={1} md={2} lg={3}>
                            </Col>

                            <Col xs={24} sm={11} md={10} lg={9}>
                                <H1>Whoops!! Something went wrong. Please refresh</H1>
                            </Col>

                            <Col xs={24} sm={11} md={10} lg={9}>

                            </Col>

                            <Col xs={24} sm={1} md={2} lg={3}>
                            </Col>

                        </Row>
                    }
                </div>
            )

        }


    }
}



function mapStateToProps(state) {
    return {
        stake: state.stake,
        leaderboard: state.leaderboard
    };
}

export default connect(mapStateToProps)(Home)
