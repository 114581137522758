import React from 'react'
import { Layout, Row, Col} from 'antd';

import Breakpoint from 'react-socks';

import { Center/*, Icon */} from '../Components'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFacebookF, faTwitter, faYoutube, faReddit, faGithub, faTelegram, faMediumM, faGitlab, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';

library.add(faFacebookF, faTwitter, faYoutube, faReddit, faGithub, faTelegram, faMediumM, faGitlab, faLinkedinIn);

const Footer = (props) => {

  const footerStyles = {
    backgroundColor:"#2B3947",
    textTransform: "uppercase",
    zIndex: 1,
    position: "fixed",
    left:0,
    bottom:0,
    right:0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "left",
    fontSize: 18,
  }

  const ravenTwitter = "https://twitter.com/raven_protocol";
  const ravenTelegram = "https://t.me/ravenprotocol";
  const ravenMedium = "https://www.medium.com/ravenprotocol";
  const ravenFacebook = "https://www.facebook.com/ravenprotocol";
  const ravenLinkedIn = "https://www.linkedin.com/company/ravenprotocol";
  const ravenGitlab = "https://gitlab.com/RavenProtocol";
  const ravenWebsite = "https://ravenprotocol.com";



  return (
    <Layout.Footer style={footerStyles}>
      <div>

        <Row>

          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Breakpoint medium up>
              <a href={ravenWebsite} target="_blank" rel="noopener noreferrer">
                <div style={{width:"100", marginLeft:50, marginTop:15, fontSize:10}}>© 2020 Raven Protocol</div>
              </a>
            </Breakpoint>
            <Breakpoint small down>
              <a href={ravenWebsite} target="_blank" rel="noopener noreferrer">
                <div style={{width:"50", marginLeft:50}}>© 2020 Raven Protocol</div>
              </a>
            </Breakpoint>
          </Col>


          <Col xs={24} sm={16} md={16} lg={14} xl={14}>
            <Center></Center>
          </Col>

          <Col xs={24} sm={8} md={8} lg={6} xl={6}>
            <Row style={{marginTop:10}}>
              <Col xs={6}>
              </Col>
              <Col xs={3}>
                <a href={ravenGitlab} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGitlab} style={{ color: '#fff' }}/></a>
              </Col>
              <Col xs={3}>
                <a href={ravenTwitter} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ color: '#fff' }}/></a>
              </Col>
              <Col xs={3}>
                <a href={ravenTelegram} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTelegram} style={{ color: '#fff' }}/></a>
              </Col>
              <Col xs={3}>
                <a href={ravenMedium} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMediumM} style={{ color: '#fff' }}/></a>
              </Col>
              <Col xs={3}>
                <a href={ravenLinkedIn} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} style={{ color: '#fff' }}/></a>
              </Col>
              <Col xs={3}>
                <a href={ravenFacebook} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} style={{ color: '#fff' }}/></a>
              </Col>
            </Row>
          </Col>

        </Row>

      </div>
    </Layout.Footer>
  )
}

export default Footer
