import React, { useContext } from 'react'
import { Link } from "react-router-dom"
import { Layout, Row, Col } from 'antd';

import Breakpoint from 'react-socks';

import { Context } from '../../context'

import { Center, Icon, Button, WalletAddrShort } from '../Components'

const Header = (props) => {
  const context = useContext(Context)

  const styles = {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    backgroundColor: "#2B3947",
  }

  return (

    <Layout.Header className="header-container" style={styles} >
      <Row>
        <Breakpoint large up>
          <Col sm={8}>
            <Link to="/">
              <Icon icon="ravenlogo" style={{ height: 17 }} />
              &nbsp;
              <Icon icon="rppng" style={{ height: 15 }} />
            </Link>
          </Col>
          <Col sm={8}>
            <Center><a href="http://www.ravenprotocol.com" target="_blank" rel="noopener noreferrer"><span style={{ color: "#fff" }}>RAVEN HOMEPAGE</span></a></Center>
          </Col>
          <Col sm={8}>
            <Link to="/leaderboard"><span style={{ color: "#fff" }}>LEADERBOARD</span></Link>
            <div style={{ float: "right" }}>
              {context.wallet ?
                <Link to="/wallet"><WalletAddrShort /></Link>
                :
                <Link to="/wallet/unlock">
                  <Button fill>
                    Connect my Wallet
                  </Button>
                </Link>
              }
            </div>
          </Col>
        </Breakpoint>

        <Breakpoint medium down>
          <Col xs={24}>
            <Link to="/">
              <Icon icon="ravenlogo" style={{ height: 30 }} />
              &nbsp;
              <Icon icon="rppng" style={{ height: 15 }} />
            </Link>
          </Col>
        </Breakpoint>


      </Row>
    </Layout.Header>



  )
}

export default Header
